import React from "react";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

export const Residential5 = () => {
  return (
    <div>
      <div
        className="page-title dark-background"
        style={{
          backgroundImage: "url(assets/img/residential5/res5-HEADER.jpg)",
        }}
      >
        <div className="container position-relative">
          <h1>Residential</h1>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Residential</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="bentomain">
        <div className="bento-main">
          <div className="bento-text">
            <h3>OUR PROJECT</h3>
            <p>
              <span>RESIDENCE</span> - “ANGLE DWELL”
            </p>
            <p>
              <span>CLIENT </span> - Mr. RAGHU & FAMILY
            </p>
            <p>
              {" "}
              <span>PROJECT YEAR</span> - 2022
            </p>
            <p>
              <span>LOCATION </span> - PARANUR, VILLUPARAM DIST
            </p>
          </div>
          <div className="bento-text">
            <p>
              <span>Site/Context</span>{" "}
            </p>
            <p>
              The project is situated in Mukkudal, a panchayat on the outskirts
              of Ambasamudram along Tenkasi Road, an area primarily
              characterized by agricultural activity. The site spans
              approximately 1.5 acres and is entirely made up of red soil,
              isolated from the surrounding neighborhood.
            </p>
            <br></br>
            <span>Program</span>
            <p>
              The initial concept involves creating a holiday retreat for a
              family and their friends to gather on their farmland. The design
              brief required a two-bedroom house with a living area, a kitchen,
              and a central staircase within a 320 sq.ft. ground area. The
              clients were open to exploring unconventional building materials.
            </p>
            <br></br>
            <span>Architectural response and climate</span>
            <p>
              The remote location posed several challenges, including lack of
              power and water connections, inadequate transport facilities, and
              poor soil conditions, which initially impeded progress. A raised
              plinth became essential to address these issues, helping define
              the scale relative to the site edges and protecting against
              termites and potential flooding, as the site slopes toward the
              south. The hot, humid climate and heavy monsoon rains made the
              raised plinth even more critical.
            </p>
            <p>
              The clients also envisioned future use of part of the site for an
              acupuncture clinic, so they desired a movable structure. Inspired
              by American tiny houses and aiming to reduce the carbon footprint,
              we opted for steel construction due to its local availability,
              recyclability, and ease of relocation.
            </p>
            <br></br>
            <span>Materials/details/ thermal strategies.</span>
            <p>
              We implemented a foundation of 14 raised pile foundations with a
              steel and concrete base to support a tubular steel structure. We
              utilized a double-walled design integrating cement board and
              expanded polystyrene (EPS) for insulation. The wall thickness was
              optimized based on thermal calculations,
            </p>
          </div>
        </div>
        <div className="bento">
          <div className="card1">
            <img src="assets/img/residential5/res5-1.jpg" alt="Card 1" />
          </div>
          <div className="card2">
            <img src="assets/img/residential5/res5-2.jpg" alt="Card 2" />
          </div>
          <div className="card3">
            <img src="assets/img/residential5/res5-8.jpg" alt="Card 3" />
          </div>
          <div className="card4">
            <img src="assets/img/residential5/res5-4.jpg" alt="Card 4" />
          </div>
          <div className="card5">
            <img src="assets/img/residential5/res5-5.jpg" alt="Card 5" />
          </div>
          <div className="card6">
            <img src="assets/img/residential5/res5-6.jpg" alt="Card 6" />
          </div>
          <div className="card7">
            <img src="assets/img/residential5/res5-3.jpg" alt="Card 7" />
          </div>
          <div className="card11">
            <img src="assets/img/residential5/res5-7.jpg" alt="Card 11" />
          </div>
          {/* <div className="card12">
            <img
              src="assets/img/residential2/res2-3.jpg"
              style={{ objectFit: "contain", backgroundColor: "white" }}
              alt="Card 12"
            />
          </div> */}
        </div>
      </div>

      <Footer1 />
    </div>
  );
};
export default Residential5;
