import React from "react";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

export const Residential3 = () => {
  return (
    <div>
      <div
        className="page-title dark-background"
        style={{
          backgroundImage: "url(assets/img/residential3/res3-header.png)",
        }}
      >
        <div className="container position-relative">
          <h1>Residential</h1>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Residential</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="bentomain">
        <div className="bento-main">
          <div className="bento-text">
            <h3>OUR PROJECT</h3>
            <p>
              <span>RESIDENCE</span> - “IN-OU RESIDENCE”
            </p>
            <p>
              <span>CLIENT </span> - Mr. NAVEEN & Mrs. JENITA
            </p>
            <p>
              {" "}
              <span>PROJECT YEAR</span> - 2024
            </p>
            <p>
              <span>LOCATION </span> -HOSUR, KRISHNAGIRI DIST.
            </p>
          </div>
          <div className="bento-text">
            <p>
              <span>Site/Context</span>{" "}
            </p>
            <p>
              The site is situated in between Hosur and Bagalur in NH648 toward
              Bangalore, It is an isolated residential community,Agricultural
              lands with scattered settlement houses. The plot spans
              approximately 2400 square feet, with builtup area limited to one
              plot of size 1200 sq ft with access available from west direction.
              a slightly undulated site sloping from south to north was a bit of
              a challenge in determining the plinth level of the built space.
            </p>
            <br></br>
            <span>Program</span>
            <p>
              The house is designed to accommodate a Mother and son with his
              grandparents who visit occasionally. The brief required a
              3-bedroom duplex dwelling with a central living space that
              connects the levels, along with a kitchen, home theater, formal
              ,informal balcony spaces which is outwardly looking in the
              building perimeter,A significant challenge was to incorporate 3
              bedroom house in a 600 sq ft of flooring with a car parking in a
              1200 sqft land, As the site is characterized by a climate with
              distinct wet and dry seasons.
            </p>
            <br></br>
            <span>Architectural response and climate</span>
            <p>
              As the site context is in contour we don't have a settled level to
              obtain the floor level so initial work is to get the plinth level
              with the help of random rubble masonry and the super structure is
              erected on that
            </p>
            <p>
              Since it is a west facing plot , the entrance as per vastu is
              preferred in the north and east directions, so thoughtfully the
              entrance was placed in the east direction as the adjacent open
              plot can also have access in future development.
            </p>
            <p>
              Since we have a budget constraint, we went for a folded metal
              staircase rather than conventional concrete staircase for a
              minimalistic aesthetic design also reducing the cost and time ,
              the staircase connects only to the first floor creating a high
              ceiling helps us to mitigate the hot air upwards , keep the ground
              floor cooler. All enclosed spaces in the house have two sizes of
              window, one being larger in size and other smaller; this helps in
              good air exchange and window flow , also the plot facing the west
              direction thoughtfully we have given shorter width and longer
              windows and eliminating the standard window dimensions eliminating
              the unwanted heat gain.
            </p>
            <p>
              A small koi pond with a central planter box is placed adjacent to
              the staircase , which is visible from the first floor passage as
              we move down and also visible from the ground floor area giving an
              aesthetic pleasing view.
              {/* The first floor has a formal balcony with
              a double door which has a good viewing coverage of the garden
              space and house entrance. Also this space has a l shaped planter
              box with curtain creepers which helps in reducing the heat gain in
              the parking area and also giving them a biophilic design. */}
            </p>
            <br></br>
            <span>Materials/details/ thermal strategies.</span>
            <p>
              We used locally accessible stones for the boundary walls and
              foundations because the site is bordered by quarries.We
              strategically used terracotta jali to manage heat and air exchange
              efficiently. We experimented with different types of flooring in
              the space; in the living and kitchen, we used polished kota stone,
              and in the bedrooms, we used vitrified tiles, to create a contrast
              look. Steels were prominently incorporated into the plan in order
              to reduce the construction cost and also to make it user-friendly.
              Darker hues are employed for the skirting, engineered wood is
              chosen for the bedroom doors, and teak wood is used for the
              windows.
            </p>
          </div>
        </div>
        <div className="bento">
          <div className="card1">
            <img src="assets/img/residential3/res3-1.jpg" alt="Card 1" />
          </div>
          <div className="card2">
            <img src="assets/img/residential3/res3-2.jpg" alt="Card 2" />
          </div>
          <div className="card3">
            <img src="assets/img/residential3/res3-3.jpg" alt="Card 3" />
          </div>
          <div className="card4">
            <img src="assets/img/residential3/res3-4.jpg" alt="Card 4" />
          </div>
          <div className="card5">
            <img src="assets/img/residential3/res3-5.jpg" alt="Card 5" />
          </div>
          <div className="card6">
            <img src="assets/img/residential3/res3-6.jpg" alt="Card 6" />
          </div>
          <div className="card7">
            <img src="assets/img/residential3/res3-7.jpg" alt="Card 7" />
          </div>
          <div className="card11">
            <img
              src="assets/img/residential3/res3-8.png"
              alt="Card 11"
              style={{ objectFit: "cover" }}
            />
          </div>
          {/* <div className="card12">
            <img
              src="assets/img/residential2/res2-3.jpg"
              style={{ objectFit: "contain", backgroundColor: "white" }}
              alt="Card 12"
            />
          </div> */}
        </div>
      </div>

      <Footer1 />
    </div>
  );
};
export default Residential3;
