import React from "react";
import { Link } from "react-router-dom";
import Footer1 from "../navbar/Footer1";

export const Residential8 = () => {
  return (
    <div>
      <div
        className="page-title dark-background"
        style={{ backgroundImage: "url(assets/img/residential8/res8-9.jpg)" }}
      >
        <div className="container position-relative">
          <h1>Residential</h1>
          <nav className="breadcrumbs">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">Residential</li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="bentomain">
        <div className="bento-main">
          <div className="bento-text">
            <h3>OUR PROJECT</h3>
            <p>
              <span>RESIDENCE</span> - “RAMAKRISHNAN RESIDENCE”
            </p>
            <p>
              <span>CLIENT </span> -Mr.SUNDARANOORTHI & Mrs. GOMATHI
              SUNDARAMOORTHI
            </p>
            <p>
              {" "}
              <span>PROJECT YEAR</span> - 2021
            </p>
            <p>
              <span>LOCATION </span> - PALACODE, DHARMAPURI DIST
            </p>
          </div>
          <div className="bento-text">
            <p>
              <span>Site/Context</span>{" "}
            </p>
            <p>
              The site is located on the outskirts of Palacode along Hogenakkal
              Road, an area primarily characterized by industrial activity. It
              spans approximately 1260 square feet and is accessible from both
              the west. The residence is situated within an industrial compound.
            </p>
            <br></br>
            <span>Program</span>
            <p>
              The house was designed for a family consisting of a couple, their
              two daughters, and their grandfather. The design brief called for
              a three-bedroom home, a living area, a kitchen, and a central
              staircase to connect the floors. A key challenge was to
              incorporate Vastu principles while meeting the programmatic needs.
            </p>
            <br></br>
            <span>Architectural response and climate</span>
            <p>
              The site is part of a developing area, necessitating a raised
              plinth which also serves to define the house’s scale relative to
              its surroundings. The house is oriented north-south along its
              shorter axis due to the site’s dimensions and the western
              orientation. The east and north sides are adjacent to factories.
              To optimize comfort, the design harnesses prevailing breezes and
              sunlight through larger openings on the northern side of the
              house.
            </p>
            <br></br>
            <span>Materials/details/ thermal strategies.</span>
            <p>
              Interior surface temperature readings taken with an infrared
              thermometer post completion give a stable and consistent reduction
              in temperature by about 10 deg.c during the summer months.Air
              exchange is carried out by stack effect , making a comfortable
              room temperature.
            </p>
          </div>
        </div>
        <div className="bento">
          <div className="card1">
            <img src="assets/img/residential8/res8-1.jpg" alt="Card 1" />
          </div>
          <div className="card2">
            <img src="assets/img/residential8/res8-2.jpg" alt="Card 2" />
          </div>
          <div className="card3">
            <img src="assets/img/residential8/res8-1.jpg" alt="Card 3" />
          </div>
          <div className="card4">
            <img src="assets/img/residential8/res8-4.jpg" alt="Card 4" />
          </div>
          <div className="card5">
            <img src="assets/img/residential8/res8-6.jpg" alt="Card 5" />
          </div>
          <div className="card6">
            <img src="assets/img/residential8/res8-3.jpg" alt="Card 6" />
          </div>
          <div className="card7">
            <img src="assets/img/residential8/res8-10.jpg" alt="Card 7" />
          </div>
          <div className="card11">
            <img src="assets/img/residential8/res8-9.jpg" alt="Card 11" />
          </div>
          {/* <div className="card12">
            <img
              src="assets/img/residential2/res2-3.jpg"
              style={{ objectFit: "contain", backgroundColor: "white" }}
              alt="Card 12"
            />
          </div> */}
        </div>
      </div>

      <Footer1 />
    </div>
  );
};
export default Residential8;
